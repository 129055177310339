import React from 'react';
import { Link } from 'react-router-dom';
import Media from '../Media'
import client from '../../client';
import groq from 'groq';

import './home.scss';

export default class Home extends React.Component {

  constructor(props) {
    super(props);

    this.state = {}
  }

  componentDidMount = async () => {
    const result = await client.fetch(groq`*[_type == "home"][0]{
      ...,
      video {
        asset->
      }
    }`);

    this.setState({
      video: result.video.asset,
      logo: result.videoLogo,
      opacity: result.opacity
    })
  }

  render() {
    return (
      <div id="home">
        <div className="welcome">
          <Link className="bg" to="/work">
            <Media type="video" src={ this.state.video } autoPlay loop />
          </Link>
        </div>
        <Link className="bottom" to="/work">enter</Link>
      </div>
    )
  }
};
