import React from 'react';
import Media from '../Media'
import { Link, useLocation } from 'react-router-dom'
import Logo from './logo.svg'

import './header.scss';

function CreateLink(props) {
    return <Link to={props.to} className={(props.to === useLocation().pathname) ? 'active' : 'inactive'}>{props.children}</Link>
}

export default class Header extends React.Component {

  render() {
    return(
      <div className="header">
        <Link to="/" className="Logo">
          <img src={Logo} alt="Litka" width="140" />
        </Link>
        <nav>
          <ul>
            <li>
              <CreateLink to="/work">Work</CreateLink>
            </li>
            <li>
              <CreateLink to="/contact">Contact</CreateLink>
            </li>
          </ul>
        </nav>
      </div>
    )
  }
}
